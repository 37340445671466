#landing {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}

#landing-img-container {
  width: 100%;
  min-height: 55vh;
  overflow: hidden;
  position: relative;
  background: transparent;
}

#landing-body {
  border-top: 3px solid var(--background-alt);
  width: 100%;
  min-height: 45vh;
  background: var(--background);
}

#landing-img-overlay {
  text-align: center;
  font-size: 6em;
  font-weight: bold;
  color: var(--background);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 4px 4px black;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

#landing-info {
  width: 60%;
  margin: 0 auto;
}
