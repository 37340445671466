.section {
  min-height: 100%;
  min-height: -webkit-fill-available;
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  background: var(--background);
}

.section-title {
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  height: 10%;
  margin-top: 1%;
}

.section-body {
  min-width: 50%;
  max-width: 90%;
  width: 60%;
  padding-bottom: 8%;
  padding-top: 2%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media (max-width: 678px) {
  .section-body {
    width: 80%;
  }

}

