.menu-item {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  border-top: 2px dotted var(--background-alt);
}

.menu-item:hover {
  font-weight: bolder;
}

.name-desc {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 75%;
}

.item-name {
  font-weight: bold;
  font-size: 1.2em;
}

