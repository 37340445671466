@import url('https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

#root {
  font-family: 'Open Sans', sans-serif;
  --coral: #F87060;
  --background: #FEFEFE;
  --background-alt: #DDD;
  --text: #222222;
  height: 100%;
  width: 100%;
  font-size: 1em;
}

#body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 5vh;
  height: 95vh;
  overflow-x: hidden;
  overflow-y: visible;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#landing-background {
  min-height: 100%;
  min-width: 100%;
  background-image: url("./img/landing.webp");
  background-attachment: fixed;
  background-position: center top;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: -100;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 678px) {
  #landing-background {
    height: 100%;
    width: 100%;
  }
}

a {
  color: var(--coral);
}

a:hover {
  color: var(--text);
}


@media (max-width: 1024px) {
  #root {
    font-size: .9em;
  }
}