.col-section-3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.col-3 {
  width: 30%;
  min-width: 275px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.col-title {
  width: 100%;
  /*border-bottom: 4px solid black;*/
  /*border-top: 4px solid black;*/
  font-size: 2em;
  font-weight: bold;
  padding-left: 5px;
  text-align: center;
  text-decoration: underline var(--text);
  padding-bottom: 5px;
}

.col-body {
  width: 100%;
  height: 87%;
  font-family: Courier;
}