.hour {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  padding-left: 30px;
  line-height: 2em;
}

.hour > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}