#nav {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 5vh;
  display: flex;
  align-items: center;
  z-index: 200;
  background: transparent;
}

#navbrand {
  height: 90%;
  cursor: pointer;
  text-decoration: none;
  margin-left: 1vw;
  color: var(--background);
}

#navbrand > img {
  max-width: 100%;
  max-height: 100%;
}

#spacer {
  flex: 1;
}

#nav-items {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.nav-item {
  padding: 0 2rem;
  text-decoration: none;
  font-size: 1.2em;
  z-index: 200;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 2px 2px black;
}

.nav-item > a {
  text-decoration: none;
  color: var(--background);
  z-index: 100;
  font-weight: bold;
}

.nav-item > a:hover {
  color: var(--coral);
}

@media (max-width: 678px) {
  #nav-items {
    display: none;
  }
}
