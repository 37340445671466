#map {
  min-height: 60vh;
  width: 100%;
}

#where > .section-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#where > .section-body > div {
  padding: 1%;
  text-align: center;
}